

















































import Vue from 'vue';
import { WorkspaceFolderModel, translate, WorkspaceObjectModel } from '@/apis/models/WorkspaceObjectModel';
import Breadcrumb from '@/components/workspace/Breadcrumb.vue';
import FolderApi from '@/apis/FolderApi';

export default Vue.extend({
  components: {
    Breadcrumb,
  },
  props: {
    selection: {
      type: Array as () => Array<WorkspaceObjectModel>,
      required: true,
    },
  },
  data() {
    return {
      translate,
      outsideFolder: null as WorkspaceFolderModel | null,
      insideCurrentFolder: null as WorkspaceFolderModel | null,
      notAllowToShow: false,
    };
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    beforeOpen(event: any) {
      this.outsideFolder = event.params.currentFolder;
      this.insideCurrentFolder = event.params.currentFolder;
    },
    submit() {
      if (this.insideCurrentFolder) {
        this.$emit('submit', this.insideCurrentFolder.uuid);
      }
    },
    cancel() {
      this.$modal.hide('move-position-modal');
    },
    async visitFolder(folderId: string) {
      this.notAllowToShow = false; // 回復原始設定
      const folderInSeletion = [] as WorkspaceObjectModel[];
      this.selection.forEach((element: WorkspaceObjectModel) => {
        if (element.metadata.type === 'WorkspaceFolder') {
          folderInSeletion.push(element);
        }
      });
      if (folderInSeletion.length !== 0) {
        for (let index = 0; index < folderInSeletion.length; index += 1) {
          if (folderId === folderInSeletion[index].uuid) {
            console.log(folderInSeletion[index].uuid);
            this.notAllowToShow = true; // 如果選取的列表中有folder的話，要避免user去按到已選取的資料夾及其以下資料夾
          }
        }
      }
      try {
        this.insideCurrentFolder = await FolderApi.retrieve(folderId);
      } catch (error) {
        console.error(error);
        this.$notify({
          type: 'error',
          title: this.$t('錯誤').toString(),
          text: this.$t('讀取資料夾失敗，請稍後重試').toString(),
        });
      }
    },
  },
});
