





























import Vue from 'vue';
import { WorkspaceFolderModel, WorkspaceObjectModel } from '@/apis/models/WorkspaceObjectModel';
import WorkspaceObjectItem from '@/components/test_maker/WorkspaceObjectItem.vue';
import { Permission } from '@/apis/models/WorkspaceSoftLinkModel';

export default Vue.extend({
  components: {
    WorkspaceObjectItem,
  },
  props: {
    selection: {
      type: Array as () => Array<WorkspaceObjectModel>,
      required: true,
    },
    selectModeOpen: {
      type: Boolean,
      required: true,
    },
    subfolders: {
      type: Array as () => Array<WorkspaceFolderModel>,
      required: true,
    },
  },
  data() {
    return {
      Permission,
    };
  },
  computed: {
    currentFolder(): WorkspaceFolderModel | null {
      return this.$store.state.currentFolder;
    },
  },
  methods: {
    toggleSelection(item: WorkspaceFolderModel) {
      this.$emit('toggle', item);
    },
    visitFolder(item: any) {
      let folderId = item.uuid;
      if (item.permission) {
        if (item.permission === Permission.EDITOR) {
          folderId = item.sourceObject.uuid;
          const currentFolderId = this.$store.state.currentFolder.uuid;
          if (folderId !== currentFolderId) {
            // change params.folderId to switch folder
            this.$router.push({
              params: {
                folderId,
              },
            });
          }
        } else if (item.permission === Permission.DETAIL_VIEWER) {
          // eslint-disable-next-line no-alert
          window.alert('權限不足');
        } else if (item.permission === Permission.GENERAL_VIEWER) {
          // eslint-disable-next-line no-alert
          window.alert('權限不足，僅可使用');
        }
      } else if (!this.selectModeOpen) {
        const currentFolderId = this.$store.state.currentFolder.uuid;
        if (folderId !== currentFolderId) {
        // change params.folderId to switch folder
          this.$router.push({
            params: {
              folderId,
            },
          });
        }
      }
    },
  },
});
