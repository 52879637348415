import FolderApi from '@/apis/FolderApi';
import store from '@/store';

export default class WorkspaceHandler {
  /**
   * Fetch specified folder and put the fetched folder data into vuex
   *
   * @param folderId the folder id intended to fetch
   */
  static async loadFolder(folderId: string): Promise<void> {
    try {
      store.commit('updateLoading', true);
      store.commit('resetCurrentFolder');

      const folder = await FolderApi.retrieve(folderId);

      store.commit('updateCurrentFolder', folder);
    } finally {
      store.commit('updateLoading', false);
    }
  }
}
